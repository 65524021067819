import * as React from "react"
import {useState, useEffect} from "react"

// components
import Header from "../components/header"
import Banner from "../components/banner"
import MediaBox from "../components/media-box"
import MediaEmbed from "../components/media-embed"
import Footer from "../components/footer"
import SEO from "../components/seo"

import { Container, Button, Modal } from 'react-bootstrap'
import BackgroundImage from 'gatsby-background-image'
import { renderToString } from 'react-dom/server';
import { Parallax, Background } from 'react-parallax';

// images

import play from '../images/play.png'


import grayLinesBG from '../images/background_stack.jpg'


///////


import * as styles from './page.module.scss'


const Page = ({ pageContext }) => {
  const post = pageContext
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const videoBackgroundFluidImageStack = [
    post.acfPageHeader.videoBoxImage.localFile.childImageSharp.fluid,
    `linear-gradient(0deg,rgba(0,0,0,.3),rgba(0,0,0,.8))`,
  ].reverse()

  useEffect(() => {
      let blar = document.getElementById('content');
      if (blar !== null) {
          blar.outerHTML = blar.innerHTML;
      }
  });

  return (
    <main>
        <style>
                {`.react-parallax-background-children { width: 100%; min-width: 1600px;}`}
                {/*{`.parallax-wrapper { background-color: #040e18;}`}*/}
                {/*{`.react-parallax { background: linear-gradient(90deg,rgba(0,156,101,0.7),rgba(0,156,101,0.2));}`}*/}

                {`.react-parallax-background.custom-bg:before {
                  content: "";
                  display: block;
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  background: linear-gradient(90deg,rgba(0,156,101,0.7),rgba(0,156,101,0.2));
                }`}

                {`#partnership-box h2 {
                  color: #4a4747;
                }
                #partnership-box .container .row {
                    display: flex;
                }

                #partnership-box .container .row div:first-child {
                    order: 2;
                }

                #partnership-box .container .row .col div {
                    padding-top: 0;
                }

                #partnership-box .container hr,
                #partnership-box .container h4,
                #partnership-box .container h2 {
                  display: none;
                }
                `}

                {`.page-content .container > .row {
                  background-color: white;
                  margin: 0 !important;
                  padding: 1rem calc(1.5rem - 15px);
                }

                .page-content .container.order-1 > .row,
                .page-content .container.order-4 > .row {
                  padding-top: 3rem;
                }

                .page-content .container:nth-last-child(2) > .row {
                  padding-bottom: 3rem;
                }
                `}
              </style>
        <SEO
          title={post.title}
          description={post.acfSEO.metaDescription}
          image={ (post.acfPageHeader.bannerBackgroundImage.localFile.childImageSharp.fluid.src !== undefined ) ? post.acfPageHeader.bannerBackgroundImage.localFile.childImageSharp.fluid.src : null }

          />
        <Header />

        <div className="bg-light" style={{backgroundImage: "url(" + grayLinesBG + ")", backgroundSize: '100%'}}>
          <Banner
            title={post.acfPageHeader.headerTitle}
            subtitle={post.acfPageHeader.headerSubtitle}
            bodytext={post.acfPageHeader.headerBodyText}
            backgroundImage={post.acfPageHeader.bannerBackgroundImage}
            backgroundVideoUrl={post.acfPageHeader.backgroundVideoUrl}
          >
          </Banner>
          <div style={{postion:'relative', marginTop:'-25vh'}}>
            <MediaBox
              orientation="right"
              title={post.acfPageHeader.videoBoxTitle}
              subtitle={post.acfPageHeader.videoBoxSubtitle}
              videoImage={post.acfPageHeader.videoBoxImage}
              videoUrl={post.acfPageHeader.videoBoxVideoUrl}
              >
              <div dangerouslySetInnerHTML={{__html: post.acfPageHeader.videoBoxBodyText}} className="pb-5" />
              <div className="d-lg-block d-none my-5 py-5" />
                <div className="d-lg-none mb-4">
                  <BackgroundImage
                    className="d-flex w-100"
                    Tag="div"
                    fluid={videoBackgroundFluidImageStack}
                    backgroundColor={`#040e18`}
                    style={{minHeight: "225px"}}
                  >
                    <div className="d-flex align-items-center justify-content-center w-100">
                      <button className="d-block h-100 w-100" style={{background: "none", border: "0"}} onClick={handleShow}>
                        <img src={play} alt="Play" />
                      </button>
                    </div>
                    <Modal show={show} onHide={handleClose} centered={true} size="xl">
                      <Modal.Header closeButton></Modal.Header>
                      <Modal.Body>
                        <MediaEmbed videoUrl={post.acfPageHeader.videoBoxVideoUrl}  autoplay={true} />
                      </Modal.Body>
                    </Modal>
                  </BackgroundImage>
                </div>
            </MediaBox>
          </div>
          {/*<Container className={`bg-white p-4 ${styles.pageContent}`}>*/}
          <div className={`page-content ${styles.pageContent}`}>
            {/*<div dangerouslySetInnerHTML={{__html: post.content}} />*/}

            <div className="d-flex flex-wrap">
              <div id="content" dangerouslySetInnerHTML={{__html: post.content}} />
              {/*{contentResult}*/}

              <div className="order-3 w-100">
                <div className="parallax-wrapper">
                  <Parallax
                    className={`text-white`}
                    strength={600}
                  >
                    <Background className="custom-bg">
                      {/*<img src="https://tcgcareersdev.wpengine.com/wp-content/uploads/2021/03/connor-group-careers-3.jpg" style={{width: "100%", maxHeight: "1050px"}} />*/}
                      <img src={post.acfBenefitsPage.partnershipBackgroundImage.localFile.childImageSharp.fluid.src} style={{width: "100%", maxHeight: "1050px"}} />
                    </Background>

                    <Container className="background-image-only py-5 my-5">
                      <div>
                        <Container className="bg-white px-0 px-md-5 py-5"  id="partnership-box">
                          <h2 className="text-uppercase text-center mb-0">{post.acfBenefitsPage.partnershipBoxTitle}</h2>
                          <h4 className="text-primary text-center font-italic border-bottom pb-3" style={{fontSize:'1.2rem', fontFamily: "Calendas", marginBottom: "1.5rem"}}>{post.acfBenefitsPage.partnershipBoxSubtitle}</h4>

                          <MediaBox
                            orientation="left"
                            title=""
                            subtitle=""
                            videoImage={post.acfBenefitsPage.partnershipBoxImage}
                            videoUrl={post.acfBenefitsPage.partnershipVideoUrl}
                            bgColor="bg-white"
                          >
                            <div dangerouslySetInnerHTML={{__html: post.acfBenefitsPage.partnershipBodyText}} />
                          </MediaBox>
                        </Container>
                      </div>
                    </Container>
                  </Parallax>
                </div>
              </div>
            </div>

            {/*<ContentBlockFull/>*/}
          </div>
          {/*</Container>*/}
        </div>
        <Footer />
    </main>
  )
}

export default Page
